import React from "react"
import { ServicePageLayout, layoutColorTypes } from "../../../layouts/service-page/service-page.layout"
import { getCanonicalUrl } from "../../../utils/getCanonicalUrl"

import { testimonials } from "./testimonials"
import { getStaticImgUrl } from "../../../utils/getStaticImgUrl"

const content = {
  title: "Resume Writing Services",
  whatWeOffer: {
    leftPart: "Having a high-quality resume is what makes you stand out in a crowd of similarly-skilled professionals. It's the ace up your sleeve in the job-hunting world.",
    rightPart: "If you are looking for professional resume writing services, we can lend a hand. Here at Podroom Creative, we have a team of experienced content writers who will help you write your resume top to bottom. "
  },
  processText: "Our management team will gather all the crucial information. Skilled writers will prepare the resume, and our editors will ensure that everything is in order. Contact us today for a quote.",
  testimonials: testimonials
}

const meta = {
  title: "Resume Writing Services",
  description: "Having a high-quality resume is what makes you stand out in a crowd of similarly-skilled professionals. It's the ace up your sleeve in the job-hunting world."
}

const og = {
  image: getStaticImgUrl("img_contnet_writing_full.jpg")
}

const pageSlug = "content-services/resume-writing";

export default () => (

  <ServicePageLayout 

    meta={meta}
    og={og}
    layoutColorType={layoutColorTypes.YELLOW}
    rightHeroImage={"/img_contnet_writing_full.jpg"}
    headerIcon={"/ico_content_writing_2@2x.png"}
    content={content}
    canonicalUrl={getCanonicalUrl(pageSlug)}

  />

)